.btn-group-units-acquirers {
  width: 100%;
}
.btn-group-units-acquirers .v-select {
  width: 100%;
}
[dir=ltr] .btn-group-units-acquirers .v-select .vs__dropdown-toggle {
  border-radius: 3px 0px 0px 3px !important;
}
[dir=rtl] .btn-group-units-acquirers .v-select .vs__dropdown-toggle {
  border-radius: 0px 3px 3px 0px !important;
}
.btn-group-units-acquirers .btn {
  width: 50px;
}
[dir] .btn-group-units-acquirers .btn {
  padding: 4px 9px !important;
}
[dir=ltr] .btn-group-units-acquirers .btn {
  border-radius: 0px 3px 3px 0px !important;
}
[dir=rtl] .btn-group-units-acquirers .btn {
  border-radius: 3px 0px 0px 3px !important;
}